import * as React from 'react';

const Slide = () => {
  return (
    <>
      <div className='slide'>
        <p>Desliza el texto para leer</p>
      </div>
    </>
  )
};

export default Slide;